$(document).on('click','#add-sign-up-job-type-btn',function(){
  $('form input[type=hidden].select-item').remove()
  $('[data-remodal-id="signupJob"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `job_type_sub_categories_id_${i} select-item`,
      name: 'user_user_manager[job_type_sub_category_ids][]',
      value: dom.value
    }).appendTo('form');
  });
});
