jQuery(function () {
  contactAreaMulti()
});

var originalIntroductionDescription = '';
$(document).ready(function() {
  if ($('#user_introduction_description').length > 0) {
    originalIntroductionDescription = $('#user_introduction_description').val();

    $('#user_introduction_save').click(() => {
      originalIntroductionDescription = $('#user_introduction_description').val();
    })

    window.onbeforeunload = function() {
      if ($('#user_introduction_description').length > 0 && $('#user_introduction_description').val() !== originalIntroductionDescription) {
        return 'このサイトを離れますか？\n行った変更が保存されない可能性があります。';
      }
    };
  }
})

// コンタクトモーダルの確認画面
function contactAreaMulti() {
  $(".contact-next-textarea").on("click", function () {
    const text = $('.contact-multi-textarea-original').select();
    $('.contact-multi-textarea').val(text.val());
  });
}
