$(document).on('click','#js-deleteBtn',function(){
  $('form.employ_search').children().remove('.member-search-item')
  $('input.asideTop_itemInput.allRadio_itemInput.typeAll').prop('checked', true);
});

$(document).on('click','#member-btn-search',function(){
  $('[data-remodal-id="job"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `job_type_sub_categories_id_in_${i} search-item`,
      name: 'q[job_type_sub_categories_id_in][]',
      value: dom.value
    }).appendTo('form');
  });

  $('[data-remodal-id="category"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `work_category_items_id_in_${i} search-item`,
      name: 'q[work_category_items_id_in][]',
      value: dom.value
    }).appendTo('form');
  });
});
