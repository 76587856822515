function sendMessage(room_id) {
  body = $("#message-" + room_id).html();
  if (body == "") {
    return
  }
  $("#body-" + room_id).val(body);
  $("#sendMessage-" + room_id).submit();
}

jQuery(function () {
  $(".sendMessage").click(function() {
    var room_id = $(this).attr('room_id');
    sendMessage(room_id);
  });
});
