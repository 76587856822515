window.pageshown = false;
$(window).on('pageshow', function(){
  window.pageshown = true;
});

jQuery(function () {
  /* common */
  smoothScroll();
  popup();
  accordionMenu();
  selectList();
  selectListGroup();
  txtCounter();
  boxAlert();
  txtAreaHeight();
  messageChenge();
  reloadBtn();
  messageList();
  messageTabChange();
  messageListHeight();
  txtSelect01();
  txtSelect02();
  txtDelete();
  selectCancel();
  bgm();
  loadImg();
  /* confirmation */
  scroll();
  txtTrim01();
  changeBackgroundImg();
  /* entry */
  txtTrim02();
  fixedScrollNav();
  /* search */
  fixedScrollSearch();
  pulldown();
  /* signup */
  onePageScroll();
  sliderSignup();
  /* top */
  sliderTop();
  // sliderTopBnr();
  tab();
  txtTrim05();
  txtTrim04();
  teamChange();
  imgCover();
  changeImg();
  sliderLoading();
  infoLoading();
  fontSizeChange();
  imgNone();
  imgPath();
  fileChange();
  /* skill */
  skillSelect();
  /* teamlist */
  searchLinkHover();
  moreBtn();
  /* entry */
  modalEnrty();
  checkEntry();
  /* topNew */
  recommendSlide();
  sliderTopNew();
  fixedScrollTop();
  scrollContact();
  teamSelect();
});

//-----------------------------------------------------
//smoothScroll
//-----------------------------------------------------
function smoothScroll() {
  $('a[href^="#"]').on('click', scrollSet);

  function scrollSet() {

    var speed = 500;
    var href = $(this).attr("href");
    var hrefList = href.split("#");
    var id = "";

    if (0 < hrefList.length) {
      id = "#" + hrefList[hrefList.length - 1];
    }

    var target = $(id == "#" || id == "" ? 'html' : id);
    var position = target.offset().top;

    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;

  }
}

//-----------------------------------------------------
// popup
//-----------------------------------------------------
function popup() {

  var $popupBtnIn = $('.header_btnAvatarIn, .header_btnWrap');

  $popupBtnIn.hover(
    function() {

      var $popupList = $('.header_btnWrap');
      var $popupBtn = $('.header_btnAvatar');

      $popupList.toggleClass('is-visible');
      $popupBtn.toggleClass('is-none');

    }
  );

  var $item = $('.header_btnTeamItem');
  var $itemetc = $('.header_btnTeamItem-etc');
  var itemlen = $item.length;
  var i = 3;

  if(itemlen > i) {
    $itemetc.addClass('is-visible');
  }

}

//-----------------------------------------------
// accordion menu
//-----------------------------------------------
function accordionMenu() {

  var $accordionTtl = $('.accordionTtl');

  $accordionTtl.on('click', function() {

    var $accordionArea = $('.accordionArea');

    $accordionArea.toggleClass('is-open');
    $accordionTtl.toggleClass('is-open');

  })

}

//-----------------------------------------------
// select list
//-----------------------------------------------
function selectList() {
  $('.selectList_parent').each( selectListElement );
}

function selectListElement( idx, dom ) {
  var $win = $(window);
  var $parent = $(dom);
  var $scroll = $parent.parents('.js-boxScroll');
  var $labels = $parent.children('li').children('label');
  
  $labels.on('click', _onClickLabel);
  
  function _onClickLabel(e) {
    var $label = $(e.currentTarget);
    var $child = $label.parent().find('.selectList_child');
    
    if ( ! $child.length ) {
      return;
    }
    if ( $label.hasClass('expanded') ) {
      $label.removeClass('expanded');
      $child.slideUp( 300, function(){
        $scroll.trigger('update.scroll');
      });
    }
    else {
      $label.addClass('expanded');
      $child.slideDown( 300, function(){
        $scroll.trigger('update.scroll');
      });
    }
  }
}

//-----------------------------------------------
// select list group
//-----------------------------------------------
function selectListGroup() {

  var $selectListGroup = $('.selectListGroup');

  if ( ! $selectListGroup.length ) {
    return;
  }

  $selectListGroup.each( selectListGroupElement );

}

function selectListGroupElement( idx, dom ) {
  var $win = $(window);
  var $base = $(dom);
  var $parentSelect = $base.find('.selectListGroup_parent_sp_select')
  var $parentList = $base.find('.selectListGroup_parent').find('.selectList');
  var $parentLabels = $parentList.find('label');
  var $childScroll = $base.find('.selectListGroup_children').find('.js-boxScroll');
  var $children = $base.find('.selectListGroup_children');
  var $child = $children.find('.selectListGroup_child');
  
  _init();
  
  function _init() {
    $parentSelect.on('change', _onChangeParentSelect);
    $parentLabels.each(function(i, dom){
      $parentLabels.eq(i).on('click', { idx: i }, _onClickParentLabel);
    });
    _update();
  }
  
  function _onChangeParentSelect(e)
  {
    _update();
  }
  
  function _onClickParentLabel(e)
  {
    $parentSelect.prop('selectedIndex', e.data.idx);
    
    _update();
  }
  
  function _update()
  {
    var i = $parentSelect.prop('selectedIndex');
    
    $parentLabels.removeClass('active');
    $parentLabels.eq( i ).addClass('active');
    
    $child.hide();
    $child.eq( i ).show();

    $childScroll.trigger('update.scroll');
    $childScroll.trigger('reset.scroll');
  }
}

//-----------------------------------------------
// txtCounter
//-----------------------------------------------
function txtCounter() {

  var $txtCounter = $('.txtCounter');

  $txtCounter.each(function(){
    var txtcount = $(this).val().length;
    $(this).next('.txtCount').find('.txtCount_in01').text(txtcount);
  })
  $txtCounter.keyup(function(){
    var txtcount = $(this).val().length;
    $(this).next('.txtCount').find('.txtCount_in01').text(txtcount);
    if(txtcount == 0){
      $(this).next('.txtCount').find('.txtCount_in01').text('0');
    }
  });
}

//-----------------------------------------------------
// boxAlert
//-----------------------------------------------------
function boxAlert() {

  var $boxAlert = $('.js-boxAlert');
  var $close = $('.js-close');

  $close.on('click', function() {
    $boxAlert.addClass('is-hide');
    $boxAlert.removeClass('is-visible');
  })

  if($('.js-boxAlert' + '.is-visible').length){
    setTimeout(function(){
      $boxAlert.addClass('is-hide');
      $boxAlert.removeClass('is-visible');
    }, 3000);
  }

}

//-----------------------------------------------------
// txtAreaHeight
//-----------------------------------------------------
function txtAreaHeight() {

  var $win = $(window);
  var $boxMessageScroll = $('.boxMessage_defScroll');
  var $boxMessageIn = $('.boxMessage_defIn');
  var $txtArea = $('.boxMessage_footInTxtarea');
  var $btnSend = $('.boxMessage_footBtn');
  var $boxMessage = $('.boxMessage');

  if ( ! $boxMessage.length ) {
    return;
  }

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  
  $win.on('resize', _onResize );
  $txtArea.on('keyup', _onKeyup );
  $btnSend.on('click', _onClickBtn );
  
  function _onPageshow() {
    _resize();
    
    $boxMessageIn.each(function(i, dom){
      var $this = $boxMessageIn.eq(i);
      $this.parent().animate({
        scrollTop: $this.height() + 10000
      });
    });
  }
  
  function _onResize() {
    _resize();
  }
  
  function _onKeyup(e) {
    $boxMessageScroll.each(function(){
      var $this = $(this);
      var $boxMessage = $this.parent('.boxMessage_defDesc');
      var $boxSend = $this.next('.boxMessage_foot');
      var boxMessageHeight = $boxMessage.outerHeight();
      var sendHeight = $boxSend.outerHeight();
      var resHeight = boxMessageHeight - sendHeight;

      $this.css({'height':resHeight});
    });
  }
  
  function _onClickBtn(e) {
    var $this = $(e.currentTarget);
    
    $this.parent().parent().parent().find('.boxMessage_defScroll').animate({
      scrollTop: $this.parent().parent().parent().find('.boxMessage_defScroll').height()
    }, 0);
  }

  function _resize() {
    $boxMessageScroll.each(function(){
      var $this = $(this);
      var $boxMessage = $('.boxMessage_defDesc');
      var $boxMessageFootHeight = $('.boxMessage_foot').outerHeight();
      var sendHeight = $this.next('div').outerHeight();
      var heightMessage = $boxMessage.outerHeight() - sendHeight - $boxMessageFootHeight;
      $this.css({'height':heightMessage});
    });
  }

}

//-----------------------------------------------------
// messageChenge
//-----------------------------------------------------
function messageChenge() {

  var $win = $(window);
  var $tab = $('.boxMessage_defTtl');
  var $inboxBtn = $('.boxMessage_inboxBtn');
  var $messageTtl= $('.boxMessage_defTtl');
  var $ico = $('.boxMessage_defTtlIco');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  $win.on('resize', _onResize);
  // $tab.on('click', _onClickTab);
  $inboxBtn.on('click', _onClickInboxBtn);
  $messageTtl.on('click', _onClickMessageTtl);
  $ico.on('click', _onClickIco);
  
  function _onPageshow() {
    _resize();
  }

  function _onResize() {
    _resize();
  }

  function _resize() {
    if ($win.width() < 768) {
      $tab.removeClass('is-act');
      $tab.off('click').on('click', _onClickTabSp);
    }
    else {
      $tab.off('click').on('click', _onClickTabPc);
    }
  }
  
  function _onClickTabPc(e) {
    var $this = $(e.currentTarget);
    $tab.removeClass('is-act');
    $this.addClass('is-act');
    $tab.parent().addClass('is-index');

    $this.parent().animate({
      scrollTop: 0
    },0);
  }
  
  function _onClickTabSp(e) {
    var $this = $(e.currentTarget);
    $tab.removeClass('is-act');
    $this.addClass('is-act');
    $tab.parent().addClass('is-index');
    $inboxBtn.parent().addClass('is-in');
    $inboxBtn.parent().removeClass('is-out');

    $this.parent().animate({
      scrollTop: 0
    },0);
  }

  function _onClickInboxBtn(e) {
    var $this = $(e.currentTarget);
    var $area = $('.boxMessage_defDesc');
    var $tab = $('.boxMessage_defTtl');

    $area.addClass('is-out');
    $this.parent().addClass('is-out');
    $this.parent().removeClass('is-in');
    $area.parent().removeClass('is-index');
    $tab.removeClass('is-act');
  }

  function _onClickMessageTtl(e) {
    var $body = $('body,html');

    $body.animate({
      scrollTop: 0
    }, 0);
    // return false;
  }

  function _onClickIco(e) {
    e.stopPropagation();
  }
}

//-----------------------------------------------------
// messageList
//-----------------------------------------------------
function messageList() {

  var $win = $(window);
  var $head = $('.header');
  var $box1 = $('.boxMessage_listUser');
  var $box3 = $('.boxMessage_def');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  $win.on('resize', _onResize);
  
  function _onPageshow() {
    _resize();
  }
  
  function _onResize() {
    _resize();
  }
  
  function _resize() {
    var winheight;
    var totalheight;
    
    if ($win.width() < 768) {
      winheight = $win.outerHeight();
      totalheight = winheight - $box1.outerHeight();
    }
    else {
      winheight = $win.height()
      totalheight = winheight - $head.height();
    }
    
    $box3.css({'height': totalheight});
  }
}

//-----------------------------------------------------
// reloadBtn
//-----------------------------------------------------
function reloadBtn() {

  var $reloadBtn = $('.boxMessageTab_reloadBtn');

  $reloadBtn.click(function() {

    location.reload();

  });

}

//-----------------------------------------------------
// messageTabChange
//-----------------------------------------------------
function messageTabChange() {

  $('.tab').click(function(){
    $('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    $('.is-show').removeClass('is-show');
    // クリックしたタブからインデックス番号を取得
    const index = $(this).index();
    // クリックしたタブと同じインデックス番号をもつコンテンツを表
    $('.panel').eq(index).addClass('is-show');
  });

}

//-----------------------------------------------------
// messageListHeight
//-----------------------------------------------------
function messageListHeight() {
// $(window).on('load resize', function(){
//   var tabHeight = $('.boxMessageTab').outerHeight();
//   var listHeight = $('.boxMessage_listUser').outerHeight();
//   var totalHeight = tabHeight + listHeight;
//   var winW = $(window).width();
//   var devW = 767;
//   if (winW <= devW) {
//     //767px以下の時の処理
//     $('.boxMessage_defTtl:nth-of-type(1)').css({'margin-top':totalHeight});
//   } else {
//     //768pxより大きい時の処理
//     $('.boxMessage_defTtl:nth-of-type(1)').css({'margin-top':'0'});
//   }
  var $win = $(window);
  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }

  $win.on('resize', _onResize);

  function _onPageshow() {
    // 開始処理
    _resize();
  }

  function _onResize() {
    _resize();
  }

  function _resize() {
    // 開始処理
    var tabHeight = $('.boxMessageTab').outerHeight();
    var listHeight = $('.boxMessage_listUser').outerHeight();
    var totalHeight = tabHeight + listHeight;
    var winW = $(window).width();
    var devW = 767;
    if (winW <= devW) {
      //767px以下の時の処理
      $('.boxMessage_defTtl:nth-of-type(1)').css({'margin-top':totalHeight});
    } else {
      //768pxより大きい時の処理
      $('.boxMessage_defTtl:nth-of-type(1)').css({'margin-top':'0'});
    }
  }
}

//-----------------------------------------------------
// txtSelect01
//-----------------------------------------------------
function txtSelect01() {

  $(document).on('confirmation', '[data-remodal-id="area"] ', function () {

    var checked = [];

    $('[data-remodal-id="area"] input:checked').each(function(i,dom){

      checked.push( $(dom).parent().text().replace(/\s+/g,'') );

    });

    var $area = $('#js-area');

    $area.html( checked.join('&nbsp;/&nbsp;') );

  });

  $(document).on('confirmation', '[data-remodal-id="job"] ', function () {

    var checked = [];

    $('[data-remodal-id="job"] input:checked').each(function(i,dom){

      checked.push( $(dom).parent().text().replace(/\s+/g,'') );

    });

    var $job = $('#js-job');

    $job.html( checked.join('&nbsp;/&nbsp;') );

    var $win = $(window);
    var cutFigurePC = '25';
    var cutFigureSP = '35';
    var afterTxt = ' ...';
    var textLength = $job.text().length;
    var textTrimPC = $job.text().substr(0,(cutFigurePC));
    var textTrimSP = $job.text().substr(0,(cutFigureSP));

    if ($win.width() > 768) {
      if(cutFigurePC < textLength) {
          $job.html(textTrimPC + afterTxt).addClass('is-visible');
      } else if(cutFigurePC >= textLength) {
          $job.addClass('is-visible');
      }
    } else {
      if(cutFigureSP < textLength) {
          $job.html(textTrimSP + afterTxt).addClass('is-visible');
      } else if(cutFigureSP >= textLength) {
          $job.addClass('is-visible');
      }
    }

  });

  $(document).on('confirmation', '[data-remodal-id="category"] ', function () {

    var checked = [];

    $('[data-remodal-id="category"] input:checked').each(function(i,dom){

      checked.push( $(dom).parent().text().replace(/\s+/g,'') );

    });

    var $category = $('#js-category');

    $category.html( checked.join('&nbsp;/&nbsp;') );

    var $win = $(window);
    var cutFigurePC = '25';
    var cutFigureSP = '35';
    var afterTxt = ' ...';
    var textLength = $category.text().length;
    var textTrimPC = $category.text().substr(0,(cutFigurePC));
    var textTrimSP = $category.text().substr(0,(cutFigureSP));

    if ($win.width() > 768) {
      if(cutFigurePC < textLength) {
          $category.html(textTrimPC + afterTxt).addClass('is-visible');
      } else if(cutFigurePC >= textLength) {
          $category.addClass('is-visible');
      }
    } else {
      if(cutFigureSP < textLength) {
          $category.html(textTrimSP + afterTxt).addClass('is-visible');
      } else if(cutFigureSP >= textLength) {
          $category.addClass('is-visible');
      }
    }

  });

  $(document).on('confirmation', '[data-remodal-id="tag"] ', function () {

    var checked = [];

    $('[data-remodal-id="tag"] input:checked').each(function(i,dom){

      checked.push( $(dom).parent().text().replace(/\s+/g,'') );

    });

    var $tag = $('#js-tag');

    $tag.html( checked.join('&nbsp;/&nbsp;') );


    var $win = $(window);
    var cutFigurePC = '25';
    var cutFigureSP = '35';
    var afterTxt = ' ...';
    var textLength = $tag.text().length;
    var textTrimPC = $tag.text().substr(0,(cutFigurePC));
    var textTrimSP = $tag.text().substr(0,(cutFigureSP));

    if ($win.width() > 768) {
      if(cutFigurePC < textLength) {
          $tag.html(textTrimPC + afterTxt).addClass('is-visible');
      } else if(cutFigurePC >= textLength) {
          $tag.addClass('is-visible');
      }
    } else {
      if(cutFigureSP < textLength) {
          $tag.html(textTrimSP + afterTxt).addClass('is-visible');
      } else if(cutFigureSP >= textLength) {
          $tag.addClass('is-visible');
      }
    }

  });

  var $inputRadio = $('.asideTop_itemInput');

  $inputRadio.on('click', function(){

    var $inputRecruit = $('.asideTop_item:nth-of-type(2) .asideTop_itemInput');
    var $pulldown = $('.asideTop_pulldown');
    var $pulldownInput = $('.asideTop_pulldownSelectInput, .asideTop_pulldownItemInput');
    var $selectTxt = $('.asideTop_pulldownSelectTxt');

    if($inputRecruit.prop('checked') == true){

      $pulldown.addClass('is-visible');

    } else {

      $pulldown.removeClass('is-visible');
      $pulldownInput.prop('checked', false);
      $selectTxt.text('募集形態');


    }

  });

}

//-----------------------------------------------------
// txtDelete
//-----------------------------------------------------
function txtDelete() {

  var $deleteBtn = $('#js-deleteBtn');
  var $selectBtn = $('.asideTop_defTtl');
  var $status = $('[name="status"]');

  $deleteBtn.click( function() {

    var $deleteTxt = $('.asideTop_defDesc');
    var $changeTxt = $('.asideTop_pulldownSelectTxt');
    var $changeInput = $('.asideTop_pulldownItemInput');
    var $remodalInput = $('.remodal input');
    var $remodalListChild = $('.remodal .selectList_child');
    var $remodalLabel = $('.remodal label');

    $deleteTxt.text('');
    $changeTxt.text('募集形態');
    $changeInput.prop('checked', false);
    $remodalInput.prop('checked', false);
    $remodalListChild.css('display','none');
    $remodalListChild.addClass('is-close');
    $remodalLabel.removeClass('expanded');


  });

  $selectBtn.click( function() {

    var $remodalListChild = $('.remodal .selectList_child');

    $remodalListChild.removeClass('is-close');

  });

  $status.change(function(){

    var $statusChecked = $('[name="status"]:checked');
    var $selectTxt = $('.asideTop_pulldownSelectTxt');
    var aryCmp = [];

    $statusChecked.each(function(index, element){

      aryCmp.push($(element).val());

    });

    $selectTxt.html(aryCmp.join('、'));

    if($statusChecked.length == 0) {

      $selectTxt.text('募集形態');

    }

  });

}

//-----------------------------------------------------
// selectCancel
//-----------------------------------------------------
function selectCancel() {

  var $closeBtn = $('.remodalClose');
  var $cancelBtn = $('.remodalCancel');

  $closeBtn.on('click',function(){
    var id = $(this).parent().attr('data-remodal-id');

    $('#js-' + id).text('');
    $(this).nextAll('.remodalBody').find('input').prop('checked', false);
    $(this).nextAll('.remodalBody').find('.selectList_child').css('display','none');
    $(this).nextAll('.remodalBody').find('label').removeClass('expanded');
  });

  $cancelBtn.on('click',function(){

    var id = $(this).parent().parent().parent().attr('data-remodal-id');

    $('#js-' + id).text('');
    $(this).parent().parent().prev('.remodalBody').find('input').prop('checked', false)
    $(this).parent().parent().prev('.remodalBody').find('.selectList_child').css('display','none');
    $(this).parent().parent().prev('.remodalBody').find('label').removeClass('expanded');
  });

}

//-----------------------------------------------------
// bgm
//-----------------------------------------------------
function bgm() {

  if($('#btn-play').length){

    var bgm1 = document.querySelector("#bgm1");       // <audio>
    var btn  = document.querySelector("#btn-play");   // <button>

    btn.addEventListener("click", ()=>{
      // pausedがtrue=>停止, false=>再生中
      if( ! bgm1.paused ){
        btn.innerHTML = '<img class="mvTopAbout_playImg" src="/assets/images/top/about_music_off_01.png" alt="OFF">';
        bgm1.pause();
      }
      else{
        btn.innerHTML = '<img class="mvTopAbout_playImg" src="/assets/images/top/about_music_on_01.png" alt="ON">';
        bgm1.play();
      }
    });

    /**
     * [event] 再生終了時に実行
     */
      bgm1.addEventListener("ended", ()=>{
      bgm1.currentTime = 0;  // 再生位置を先頭に移動
      btn.innerHTML = '<img src="/assets/images/top/about_music_off_01.png" alt="OFF">';  // 「再生ボタン」に変更
    });

    $('audio').prop('volume', 0.3);

  }

}

//-----------------------------------------------------
// loadImg
//-----------------------------------------------------
function loadImg() {

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  
  function _onPageshow() {
    // 開始処理
    if($('.mvIll').length){

      $('.mvIllImg').addClass('is-visible');
      $('.mvTopAbout_playWrap').addClass('is-visible');
      $('.ball-scale').addClass('is-hidden');

    }

    if($('.mvCompany_ill').length){

      $('.mvCompany_ill').addClass('is-visible');
      $('.mvCompany').addClass('is-visible');
      $('.ball-scale').addClass('is-hidden');

    }

    if($('.mvRecruit_ill').length){

      $('.mvRecruit_ill').addClass('is-visible');
      $('.mvRecruit').addClass('is-visible');
      $('.ball-scale').addClass('is-hidden');

    }

  }

}

//-----------------------------------------------------
// scroll
//-----------------------------------------------------
function scroll() {

  var ps_list = new Array();
  var $boxScroll = $('.js-boxScroll');

  $boxScroll.each(function(i, dom){
    ps_list.push( new PerfectScrollbar( dom ,{wheelSpeed: 0.5}) );

    $boxScroll.eq(i).on('update.scroll', { idx: i }, function(e){
      ps_list[ e.data.idx ].update();
    });
    $boxScroll.eq(i).on('reset.scroll', { idx: i }, function(e){
      ps_list[ e.data.idx ].element.scrollTop = 0;
    });
  });
  
  $(document).on('opened', '.remodal', function () {
    $.each( ps_list, function(i){
      ps_list[i].update();
    });
  });

  var $remodalClose = $('.remodalClose');
  var $remodalScroll = $('.remodalScroll');
  var $remodalConfirm = $('.remodalConfirm');
  var $selectListItem = $('.selectList li');
  var $selectListLabel = $('.selectList label');
  var $selectListLabelFirst = $('.selectList li:nth-of-type(1) label');
  var $selectListGroupChild = $('.selectListGroup_child');
  var $selectListGroupChildFirst = $('.selectListGroup_child:nth-of-type(1)');

  $remodalClose.on('click', function(){
    if($remodalScroll.length) {
      $remodalScroll.animate({scrollTop: 0}, 0);
      $selectListLabel.removeClass('active');
      $selectListLabelFirst.addClass('active');
      $selectListGroupChild.css({'display':'none'});
      $selectListGroupChildFirst.css({'display':'block'});
    }
  });

  $remodalConfirm.on('click', function(){
    if($remodalScroll.length) {
      $remodalScroll.animate({scrollTop: 0}, 0);
    }
  });

}

//-----------------------------------------------------
// changeBackgroundImg
//-----------------------------------------------------
function changeBackgroundImg() {

  var img = $('.content_recPreviewImageMain');

  if ( ! img.length ) {
    return;
  }

  var src = content_recPreviewImageMain.getAttribute('src');
  var elementReference = document.getElementById( "content_recPreviewImage" );

  var img = $('#content_recPreviewImage');

  if ( ! img.length ) {
    return;
  }

  elementReference.style.backgroundImage = "url( " + src + " )";

}

//-----------------------------------------------------
// fixedScrollNav
//-----------------------------------------------------
function fixedScrollNav() {

  var target = $('.index');
  var target02 = $('.corporateDetail .index');
  var footer = $('.footer');
  var main = $('.main');

  if ( ! target.length ) {
    return;
  }

  if ( target02.length ) {
    return;
  }

  var targetHeight = target.outerHeight(true);
  var targetTop = target.offset().top;

  $(window).scroll(function(){

    var scrollTop = $(this).scrollTop();

    if(scrollTop > targetTop){

      var footerTop = footer.offset().top;

      if(scrollTop + targetHeight > footerTop){

        customTopPosition = footerTop - (scrollTop + targetHeight)
        target.css({position: 'fixed', top: customTopPosition + 'px'});

      } else {

        target.addClass('is-fixed');
        main.addClass('is-margin');

      }

    } else {

      target.removeClass('is-fixed');
      main.removeClass('is-margin');

    }

  });

  var scrollMenu = function() {

    var $itemTerms = $('#recTerms');

    if($itemTerms.length) {

      var array = {
        '#recInfo': 0,
        '#recPoint': 0,
        '#recTerms': 0,
        '#recAbout': 0
      };

      var $globalNavi = new Array();

      for (var key in array) {
        if ($(key).offset()) {
          array[key] = $(key).offset().top - 10;
          $globalNavi[key] = $('.index_recinfoItem a[href="' + key + '"]');
        }
      }

      $(window).scroll(function () {
        for (var key in array) {
          if ($(window).scrollTop() > array[key] + 0) {

            var $item = $('.index_recinfoItem');

            $item.each(function() {
              $(this).removeClass('index_selected');
            });
            $globalNavi[key].parent().addClass('index_selected');
          }
        }
      });

    } else {

      var array = {
        '#recInfo': 0,
        '#recPoint': 0,
        '#recAbout': 0
      };

      var $globalNavi = new Array();

      for (var key in array) {
        if ($(key).offset()) {
          array[key] = $(key).offset().top - 10;
          $globalNavi[key] = $('.index_recinfoItem a[href="' + key + '"]');
        }
      }

      $(window).scroll(function () {
        for (var key in array) {
          if ($(window).scrollTop() > array[key] + 100) {

            var $item = $('.index_recinfoItem');

            $item.each(function() {
              $(this).removeClass('index_selected');
            });
            $globalNavi[key].parent().addClass('index_selected');
          }
        }
      });

    }

  }

  scrollMenu();

}

//-----------------------------------------------------
// fixedScrollSearch
//-----------------------------------------------------
function fixedScrollSearch() {

  var target = $('.asideTop');
  var target02 = $('.topNew');
  var footer = $('.footer');

  if ( ! target.length ) {
    return;
  } else if (target02.length) {
    return;
  }

  var targetHeight = target.outerHeight(true);
  var targetTop = target.offset().top - 40;

  $(window).scroll(function(){

    var scrollTop = $(this).scrollTop();

    if(scrollTop > targetTop){

      var footerTop = footer.offset().top;

      if(scrollTop + targetHeight > footerTop){

        customTopPosition = footerTop - (scrollTop + targetHeight)
        target.css({position: 'fixed', top: customTopPosition + 'px'});

      } else {

        target.css({position: 'fixed', top: '0'});
        // target.addClass('is-fixed');

      }

    } else {

      target.css({position: 'absolute', top: '0'});
      // target.removeClass('is-fixed');

    }

  });

}

//
//substr
//
function substr(text, len, truncation) {

  if (truncation === undefined) { truncation = ''; }

  var text_array = text.split('');
  var count = 0;
  var str = '';

  for (i = 0; i < text_array.length; i++) {
    var n = escape(text_array[i]);

    if (n.length < 4) count++;

    else count += 2;

    if (count > len) {
      return str + truncation;
    }

    str += text.charAt(i);

  }

  return text;

}

//-----------------------------------------------------
// txtTrim01
//-----------------------------------------------------
function txtTrim01() {

  var $win = $(window);
  
  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  
  $win.on('resize', _onResize);
  
  function _onPageshow() {
    // 開始処理
    _resize();
  }
  
  function _onResize() {
    _resize();
  }

  function _resize() {

    var SP_LEN = [ 75, 68, 76, 36, 104, 150 , 96 ];
    var PC_LEN = [ 115, 64, 64, 56, 170, 150, 96 ];

    var winW = $win.width();
    var devW = 767;
    var len = ( winW <= devW ) ? SP_LEN : PC_LEN;

    var $elements = $('.boxConfSelect_itemRecruitTxtWriteLink, .aside_otherItemWrite, .aside_recommendItemWrite');
    var $elements1 = $('.boxConfSelect_itemRecruitTxtWriteLink');
    var $elements2 = $('.aside_otherItemWrite');
    var $elements3 = $('.aside_recommendItemWrite');
    var $elements4 = $('.listInterview_itemLinkWrite');
    var $elements5 = $('.boxRecruit_contInDefDesc-project');
    var $elements6 = $('.boxListLine_itemLinkInWriteMatching');
    var $elements7 = $('.boxTopMain_recommendBusinessItemWrite');

    $elements1.each(function(i){
      var text = substr( $elements1.eq(i).text(), len[0], '...');
      $elements1.eq(i).text( text );
    });
    $elements2.each(function(i){
      var text = substr( $elements2.eq(i).text(), len[1], '...');
      $elements2.eq(i).text( text );
    });
    $elements3.each(function(i){
      var text = substr( $elements3.eq(i).text(), len[2], '...');
      $elements3.eq(i).text( text );
    });
    $elements4.each(function(i){
      var text = substr( $elements4.eq(i).text(), len[3], '...');
      $elements4.eq(i).text( text );
    });
    $elements5.each(function(i){
      var text = substr( $elements5.eq(i).text(), len[4], '...');
      $elements5.eq(i).text( text );
    });
    $elements6.each(function(i){
      var text = substr( $elements6.eq(i).text(), len[5], '...');
      $elements6.eq(i).text( text );
    });
    $elements7.each(function(i){
      var text = substr( $elements7.eq(i).text(), len[6], '...');
      $elements7.eq(i).text( text );
    });

    $elements.css({'visibility':'visible'});

  }
}

//-----------------------------------------------------
// txtTrim02
//-----------------------------------------------------
function txtTrim02() {

  var $win = $(window);
  
  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  
  $win.on('resize', _onResize);
  
  function _onPageshow() {
    // 開始処理
    _resize();
  }
  
  function _onResize() {
    _resize();
  }

  function _resize() {

    var SP_LEN = [ 75, 68, 76 ];
    var PC_LEN = [ 115, 64, 64 ];

    var winW = $win.width();
    var devW = 767;
    var len = ( winW <= devW ) ? SP_LEN : PC_LEN;

    var $elements = $('.boxConfSelect_itemRecruitTxtWriteLink, .aside_otherItemWrite, .aside_recommendItemWrite');
    var $elements1 = $('.boxConfSelect_itemRecruitTxtWriteLink');
    var $elements2 = $('.aside_otherItemWrite');
    var $elements3 = $('.aside_recommendItemWrite');


    $elements1.each(function(i){
      var text = substr( $elements1.eq(i).text(), len[0], '...');
      $elements1.eq(i).text( text );
    });
    $elements2.each(function(i){
      var text = substr( $elements2.eq(i).text(), len[1], '...');
      $elements2.eq(i).text( text );
    });
    $elements3.each(function(i){
      var text = substr( $elements3.eq(i).text(), len[2], '...');
      $elements3.eq(i).text( text );
    });

    $elements.css({'visibility':'visible'});

  }
}

//-----------------------------------------------------
// txtTrim04
//-----------------------------------------------------
function txtTrim04() {
  var $win = $(window);
  
  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  $win.on('resize', _onResize);
  
  function _onPageshow() {
    // 開始処理
    _resize();
  }
  
  function _onResize() {
    _resize();
  }

  function _resize() {
    var SP_LEN = [ 70, 68, 76, 36, 90, 92, 30 ];
    var PC_LEN = [ 90, 74, 130, 56, 90, 92, 22 ];

    var winW = $win.width();
    var devW = 767;
    var len = ( winW <= devW ) ? SP_LEN : PC_LEN;
    
    var $elements = $('.sliderArticle_itemLinkWrite, .tabArrival_inItemLinkWrite, .boxListLine_itemLinkInWrite, .listInterview_itemLinkWrite');
    var $elements1 = $('.sliderArticle_itemLinkWrite');
    var $elements2 = $('.tabArrival_inItemLinkWrite');
    var $elements3 = $('.boxListLine_itemLinkInWrite');
    var $elements4 = $('.listInterview_itemLinkWrite');
    var $elements5 = $('.listParty_itemIcoLinkWrite');
    var $elements6 = $('.boxTopPickup_itemWriteLink');
    var $elements7 = $('.boxTopMain_recommendTeamItemName');
    
    $elements1.each(function(i){
      var text = substr( $elements1.eq(i).text(), len[0], '...');
      $elements1.eq(i).text( text );
    });
    $elements2.each(function(i){
      var text = substr( $elements2.eq(i).text(), len[1], '...');
      $elements2.eq(i).text( text );
    });
    $elements3.each(function(i){
      var text = substr( $elements3.eq(i).text(), len[2], '...');
      $elements3.eq(i).text( text );
    });
    $elements4.each(function(i){
      var text = substr( $elements4.eq(i).text(), len[3], '...');
      $elements4.eq(i).text( text );
    });
    $elements5.each(function(i){
      var text = substr( $elements5.eq(i).text(), len[4], '...');
      $elements5.eq(i).text( text );
    });
    $elements6.each(function(i){
      var text = substr( $elements6.eq(i).text(), len[5], '...');
      $elements6.eq(i).text( text );
    });
    $elements7.each(function(i){
      var text = substr( $elements7.eq(i).text(), len[6], '...');
      $elements7.eq(i).text( text );
    });

    $elements.css({'visibility':'visible'});

  }
}

//-----------------------------------------------------
// pulldown
//-----------------------------------------------------
function pulldown() {

  var $input = $('.asideTop_pulldownSelectInput');
  var $arrow = $('.asideTop_pulldownSelect');

  $input.on('click', function(){

    $arrow.toggleClass('is-open');

  });

}

//-----------------------------------------------------
// onePageScroll
//-----------------------------------------------------
function onePageScroll(){

  var $win = $(window);
  var setWrap = $('.scrollSignup'),
  setBase = $('.stageBase'),
  scrollSpeed = 800,
  scrollEasing = 'swing',
  // slideSpeed = 500,
  // slideEasing = 'linear',
  downBtn = 'hide', // 'show' or 'hide'
  urlHash = 'off'; // 'on' or 'off'
  // setHash = '!page';

  var url = document.URL,
  stageSlide = $('.stageSlide');

  if ( ! setWrap.length ) {
    return;
  }

  setWrap.append('<nav class="pageNav"><ul></ul></nav>');
  setBase.each(function(i){
    $('.pageNav ul').append('<li class="pagePn'+(i+1)+'"><a href="javascript:void(0);"></a></li>');
  });

  if(downBtn == 'show'){
    setWrap.append('<div id="pageDown"><a href="javascript:void(0);"></a></div>');
  }

  var coreNav = $('.pageNav'),
  setNav = coreNav.find('ul'),
  navList = setNav.find('li'),
  navLength = navList.length;
  setNav.find('li:first').addClass('activeStage');
  $('body').attr('data-page','1');



  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  
  $win.on('resize', _onResize);
  
  function _onPageshow() {
    // 開始処理
    _resize();
    
    // OpeningFade
    $('.main').css({visibility:'visible'});

  }
  
  function _onResize() {
    _resize();
  }
  
  function _resize() {
    var wdHeight = $(window).height();
    setBase.css({height:wdHeight});

    var resizeContTop = parseInt(setWrap.css('top'));

    if(resizeContTop === 0){
      setWrap.css({top:'0'});
    } else {
      var activeStagePos = setNav.find('li.activeStage');
      activeStagePos.each(function(){
        var posIndex = navList.index(this);
        setWrap.css({top:-(wdHeight*posIndex)});
      });
    }

    coreNav.each(function(){
      var navHeight = $(this).height();
      $(this).css({top:((wdHeight)-(navHeight))/2});
    });
  }

  // MouseWheelEvent
  var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
  $(document).on(mousewheelevent,function(e){
    if(!(setWrap.is(':animated')) && !(setBase.is(':animated'))){
      // e.preventDefault();
      var delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);
      if (delta < 0){
        motionDown();
      } else {
        motionUp();
      }
    }
  });

  // ScrollUpEvent
  function motionUp(){
    var stageHeightU = setBase.height(),
    contTopUp = parseInt(setWrap.css('top')),
    moveTopUp = contTopUp + stageHeightU;
    $('input,textarea').blur();
    if(!(contTopUp === 0)){
      setBase.stop().fadeOut(200);
      setWrap.stop().animate({top:moveTopUp},scrollSpeed,scrollEasing,function(){
        setBase.fadeIn(400);
      });
      setNav.find('li.activeStage').removeClass('activeStage').prev().addClass('activeStage');

      var acvStageP = parseInt($('body').attr('data-page')),
      setPrev = acvStageP-1;
      $('body').attr('data-page',setPrev);
      if(downBtn == 'show'){
        pagePos();
      }
    }
    if(urlHash == 'on'){
      replaceHash();
    }
  }

  // ScrollDownEvent
  function motionDown(){
    var stageHeightD = setBase.height(),
    contTopDown = parseInt(setWrap.css('top')),
    moveTopDown = contTopDown - stageHeightD;
    $('input,textarea').blur();

    var contHeight = setWrap.height(),
    maxHeightAdj = -(contHeight - stageHeightD);

    if(!(contTopDown == maxHeightAdj)){
      setBase.stop().fadeOut(200);
      setWrap.stop().animate({top:moveTopDown},scrollSpeed,scrollEasing,function(){
        setBase.fadeIn(400);
      });
      setNav.find('li.activeStage').removeClass('activeStage').next().addClass('activeStage');

      var acvStageN = parseInt($('body').attr('data-page')),
      setNext = acvStageN+1;
      $('body').attr('data-page',setNext);
      if(downBtn == 'show'){
        pagePos();
      }
    }
    if(urlHash == 'on'){
      replaceHash();
    }
  }

  // SideNaviClick
  navList.click(function(){
    if(!(setWrap.is(':animated'))){
      var crtIndex = navList.index(this),
      crtHeight = $(window).height();
      setWrap.stop().animate({top:-(crtHeight*crtIndex)},scrollSpeed,scrollEasing);
      setNav.find('li.activeStage').removeClass('activeStage');
      $(this).addClass('activeStage');

      $('body').attr('data-page',crtIndex+1);

      if(downBtn == 'show'){
        pagePos();
      }
      if(urlHash == 'on'){
        replaceHash();
      }
    }
  });
}

//-----------------------------------------------------
// sliderSignup
//-----------------------------------------------------
function sliderSignup() {

  var $swipeArea = $('.sliderSignup');

  $swipeArea.slick({
    responsive: [
      {
        breakpoint: 768, //767px以下のサイズに適用
        settings: {
          autoplay: false,
          dots: true,
          speed: 1000
        }
      }
    ]
  });

}

//-----------------------------------------------------
// txtSelect02
//-----------------------------------------------------
function txtSelect02() {

  if ( ! $('[data-remodal-id="signupJob"]').length ) {
    return;
  }

  $(document).on('confirmation', '[data-remodal-id="signupJob"] ', function () {

    var checked = [];

    $('[data-remodal-id="signupJob"] input:checked').each(function(i,dom){

      checked.push( $(dom).parent().text().replace(/\s+/g,'') );

    });

    var $job = $('#js-signupJob');

    $job.html( checked.join('&nbsp;/&nbsp;') );


    var $win = $(window);
    var cutFigurePC = '30';
    var cutFigureSP = '26';
    var afterTxt = ' ...';
    var textLength = $job.text().length;
    var textTrimPC = $job.text().substr(0,(cutFigurePC));
    var textTrimSP = $job.text().substr(0,(cutFigureSP));

    if ($win.width() > 768) {
      if(cutFigurePC < textLength) {
          $job.html(textTrimPC + afterTxt).addClass('is-visible');
      } else if(cutFigurePC >= textLength) {
          $job.addClass('is-visible');
      }
    } else {
      if(cutFigureSP < textLength) {
          $job.html(textTrimSP + afterTxt).addClass('is-visible');
      } else if(cutFigureSP >= textLength) {
          $job.addClass('is-visible');
      }
    }

  });

}

//-----------------------------------------------------
// txtTrim05
//-----------------------------------------------------
function txtTrim05() {
  var $win = $(window);

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  $win.on('resize', _onResize);

  function _onPageshow() {
    // 開始処理
    _resize();
  }

  function _onResize() {
    _resize();
  }

  function _resize() {
    var SP_LEN = [ 66 ];
    var PC_LEN = [ 86 ];

    var winW = $win.width();
    var devW = 767;
    var len = ( winW <= devW ) ? SP_LEN : PC_LEN;

    var $elements = $('.listPickup_itemIcoLinkWrite');
    var $elements1 = $('.listPickup_itemIcoLinkWrite');

    $elements1.each(function(i){
      var text = substr( $elements1.eq(i).text(), len[0], '...');
      $elements1.eq(i).text( text );
    });

    $elements.css({'visibility':'visible'});

  }
}

//-----------------------------------------------------
// slider
//-----------------------------------------------------
function sliderTop() {

  var $win = $(window);
  var $sliderArticle = $('.sliderArticle_list');
  var $sliderItem = $('.sliderArticle');
  var $sliderBnr = $('.sliderBnr');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }
  
  function _onPageshow() {
    // 開始処理
    $sliderArticle.slick({
      centerMode: true,
      centerPadding: '0',
      infinite: true,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      variableWidth: true,
      focusOnSelect: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 600,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    });

    $sliderItem.on('afterChange',function(){

      var $sliderImg= $('.slick-center > a > div > img');
      var val = $sliderImg.attr('src');

      $sliderItem.css('background','url(' + val + ') center top / 100% auto no-repeat');

    });


    var $sliderImg= $('.slick-center > a > div > img');
    var val = $sliderImg.attr('src');

    $sliderItem.css('background','url(' + val + ') center center / 100% auto no-repeat');

    if ( ! $sliderBnr.length ) {
      return;
    }

    $sliderBnr.slick({
      autoplay: true,
      dots: true,
      speed: 1000
    });
  }

}

//-----------------------------------------------------
// tab
//-----------------------------------------------------
function tab() {

  var $tabBtn= $('.tabArrival_list li');
  var $tabArea= $('.tabArrival > div');

  $tabBtn.click(function() {

    var index = $tabBtn.index(this);

    $tabBtn.removeClass('tabArrival_item-act').addClass('tabArrival_item');
    $(this).addClass('tabArrival_item-act');
    $tabArea.addClass('tabArrival_in').eq(index).addClass('tabArrival_in-act').removeClass('tabArrival_in');

  });

}

//-----------------------------------------------------
// teamChange
//-----------------------------------------------------
function teamChange() {

  var $btnSelect = $('.boxTtlBtn_btnLink');
  var $boxTeam = $('.boxMember');
  var $btnRadio = $('.inputTeam');
  var $btnChange = $('.remodalChange');

  $boxTeam.first().addClass('is-active');

  $btnSelect.on('click', function() {

    var $listRadio = $('.remodalMember_item');
    var index = $('.is-active').index('.boxMember');

    $listRadio.eq(index).find('.inputTeam').prop('checked', true);

  });

  $btnRadio.on('click', function() {

    var index = $btnRadio.index(this);

    $btnChange.on('click', function() {

      $boxTeam.removeClass('is-active');
      $boxTeam.eq(index).addClass('is-active');

    });

  });

  var $icoList = $('.boxMember_defList');

  $icoList.each(function() {

    var len = $(this).find('.boxMember_defItem').length;

    if(len > 2) {

      $(this).next('.boxMember_defNum').text('+' + (len - 2));

    }

  });

}

//-----------------------------------------------------
// imgCover
//-----------------------------------------------------
function imgCover() {

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  function _onPageshow() {
    // 開始処理
    var imgBoxClass = [
      'mvCover',
      'boxMember_ico',
      'boxMember_defItemLink',
      'tabArrival_inItemLinkPic',
      'listParty_itemIcoLinkImg',
      'boxListLine_itemLinkThumb',
      'listInterview_itemLinkThumb',
      'listInterview_itemLinkLogoImgBox',
      'boxRecruit_contImgBox',
      'aside_recommendItemPic',
      'boxConfSelect_itemSelectRowInImgBox',
      'boxConfSelect_itemSelectRowInItem',
      'boxConfSelect_itemRecruitImgBox',
      'boxProf_ico',
      'boxActivity_itemImgBox',
      'boxBookmark_itemPic',
      'boxMessage_itemUserIco',
      'boxMessage_defTtlIco',
      'boxMessage_defTtlIco-act',
      'boxMessage_defTtlIco-mark',
      'boxCover_imgBox',
      'aside_teamItemLinkIn',
      'header_btnAvatarIn',
      'header_btnTeamItemLinkIco',
      'js-boxRecPre_infoThumbItem',
      'js-boxRecPre_infoImg',
      'content_recPreviewCompImageIn',
      'sliderArticle_itemLinkPic',
      'boxRecTeamList_inInIcoImg',
      'boxTopMain_recommendSlideItemTtlIco',
      'boxTopMain_recommendSlideItemPic',
      'boxTopMain_recommendBusinessItemTtlIco',
      'boxTopPickup_itemTeamIco',
      'boxListLine_itemLinkInCompanyLogo',
      'boxTopMain_recommendTeamActiveIco',
      'boxTopMain_recommendTeammemberSlideItemIco',
      'boxTopMain_recommendTeamItemIco'
    ]

    if ( ! imgBoxClass.length ) {
      return;
    }

    for ( var i = 0;  i < imgBoxClass.length;  i++ ) {

      if($('.' + imgBoxClass[i]).length) {

        // var img = $('.' + imgBoxClass[i] + ' img');
        // var imgSrc = img.attr('src');

        $('.' + imgBoxClass[i]).each(function(){

          var img = $('img', this);
          var imgSrc = img.attr('src');

          // img.css({'display': 'none'});
          img.addClass('imgNone');

          $(this).prepend('<span class="imgCover"></span>');
          // $(this).css({'background-size':'cover', 'background-image':'url(' + imgSrc + ')', 'background-position':'center center'});
          $('.imgCover',this).css({'background-image':'url(' + imgSrc + ')'});

          // var property = window.getComputedStyle($(this)[0], '::before').getPropertyValue('content');
          // alert(property);

        });

        // $('.' + imgBoxClass[i]).css({'background-size':'cover', 'background-image':'url(' + imgSrc + ')', 'background-position':'center center'});
        // img.css({'display': 'none'});

      }

    }

  }

}

//-----------------------------------------------------
// changeImg
//-----------------------------------------------------
function changeImg() {
  var $item = $('.js-boxRecPre_infoThumbItem');
  var thumbLen = $('.js-boxRecPre_infoThumbItem').length;

  $item.eq(0).addClass('is-act')

  $item.on('click',function(){
    var $itemCover = $('#boxRecPre_infoImg .imgCover');
    var dataUrl = $('.imgCover', this).attr('style');

    $item.removeClass('is-act');
    $(this).addClass('is-act');
    $itemCover.attr('style', dataUrl);

  });

  if(thumbLen < 2) {
    var $itemImg = $('#boxRecPre_infoThumbImg');

    $itemImg.addClass('is-hidden')

  }

}

//-----------------------------------------------------
// sliderLoading
//-----------------------------------------------------
function sliderLoading() {

  var $box = $('.sliderArticle');
  var $txt = $('.sliderArticle_itemLinkWrite');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  function _onPageshow() {
    // 開始処理
    $box.addClass('is-visible');
    $txt.addClass('is-visible');
  }

}

//-----------------------------------------------------
// infoLoading
//-----------------------------------------------------
function infoLoading() {

  var $box = $('.boxInfo_in');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  function _onPageshow() {
    // 開始処理
    $box.addClass('is-visible');
  }

}

//-----------------------------------------------------
// imgNone
//-----------------------------------------------------
function imgNone() {

  var $imgBox = $('.boxProf_ico-cover');
  var $img = $('.boxProf_icoImg');
  var $txt = $('.boxProf_fileInputTxt');
  var $teams = $('.teamprofileAbout, .teamprofileCreate');
  var imgLen = $img.length;
  var teamsLen = $teams.length;

  if(!imgLen) {
    if(teamsLen) {
      $txt.text('チームアイコンを登録する');
    } else {
      $txt.text('アイコンを登録する');
    }
    $imgBox.addClass('boxProf_ico-cover-none')
  } else {
    $imgBox.removeClass('boxProf_ico-cover-none')
  }

}

//-----------------------------------------------------
// imgPath
//-----------------------------------------------------
function imgPath() {

  var $img = $('.boxProf_icoImg');
  var imgLen = $img.length;

  if(imgLen) {

    var $imgName = $('.boxProf_fileInputTxt');
    var path = $('.boxProf_ico-cover .imgCover').attr('style');
    var match = path.match(/[^/]+$/);

    $imgName.text(match);

    var txt = $imgName.text();

    $imgName.text(
      txt.slice(0, -3)
    );

  }

}

//-----------------------------------------------------
// fileChange
//-----------------------------------------------------
function fileChange() {

  var $input = $('.boxProf_fileInput');

  $input.change(function(e){
    //ファイルオブジェクトを取得する
    var file = e.target.files[0];
    var reader = new FileReader();

    //画像でない場合は処理終了
    if(file.type.indexOf("image") < 0){
      alert("画像ファイルを指定してください。");
      return false;
    }

    //アップロードした画像を設定する
    reader.onload = (function(file){
      return function(e){
        // $(".boxProf_icoImg").attr("src", e.target.result);
        $(".boxProf_ico-cover .imgCover").css("background-image", "url(" + e.target.result + ")");
        $(".boxProf_fileInputTxt").text(file.name);
        $('.boxProf_ico-cover').removeClass('boxProf_ico-cover-none');
      };
    })(file);
    reader.readAsDataURL(file);

  });

}

//-----------------------------------------------------
// skillSelect
//-----------------------------------------------------
function skillSelect() {
  var $elements = $('.boxSkillSelect');
  $elements.each( skillSelectElement );
}

function skillSelectElement( idx, dom ) {
  var $base = $(dom);
  var $input = $base.find('.js-skill-input');
  var $select = $base.find('.js-skill-select');
  var $options = $select.children();
  var $ui = $base.find('.js-skill-ui');
  var $list = $base.find('.js-skill-list');
  var $tags = $base.find('.js-skill-tags');

  _init();

  function _init() {
    $ui.hide();
    $input.on('focus', _onFocusInput);
    $input.on('blur', _onBlurInput);
    // $input.on('keyup', _onKeyupInput);
    // $input.on('change', _onChangeInput);

    _updateList();
    _updateTags();
  }

  function _onFocusInput() {
    $ui.show();
  }

  function _onBlurInput() {
    setTimeout(function(){
      $ui.fadeOut(100);
    }, 100);
  }

  function _onKeyupInput() {
    _updateList();
  }

  function _onChangeInput() {
    _updateList();
  }

  function _updateList() {
    $list.empty();

    var value = $input.val();
    if ( value == '' ) {
      $options.each(function(i){
        _addListItem(i);
      });
    }
    else {
      $options.each(function(i){
        if ( _isMatch( i, value ) ) {
          _addListItem(i);
        }
      });
    }
  }

  function _isMatch( index, keyword ) {
    var $option = $options.eq( index );
    var name = $option.attr('data-skill-name');
    var match = $option.attr('data-skill-match');
    var list = ( ! match || match == '' ) ? [] : match.split(',');
    var len = list.length;
    var i;

    if ( name.indexOf( keyword ) >= 0 ) {
      return true;
    }
    for ( i = 0; i < len; i ++ ) {
      if ( list[i].indexOf( keyword ) >= 0 ) {
        return true;
      }
    }

    return false;
  }

  function _updateTags() {
    $tags.empty();

    $options.each(function(i) {
      if ( $options.eq(i).prop('selected') ) {
        _addTagItem(i);
      }
    });
  }

  function _addListItem( index ) {
    var $option = $options.eq( index );
    var $item = $(document.createElement('a'));
    $item.text( $option.attr('data-skill-name') );
    $item.on('click', { index: index }, _onClickListItem);
    $item.appendTo( $list );
  }

  function _onClickListItem(e) {
    var index = e.data.index;
    $options.eq( index ).prop('selected', true);
    _updateTags();
  }

  function _addTagItem( index ) {
    var $option = $options.eq( index );
    var $item = $(document.createElement('li')).addClass('listTag_item');
    var $span = $(document.createElement('span')).appendTo( $item );
    $span.text( $option.attr('data-skill-name') );
    var $button = $(document.createElement('button')).addClass('listTag_itemBtn').appendTo( $item );
    $button.on('click', { index: index }, _onClickTagItem);
    $item.appendTo( $tags );
  }

  function _onClickTagItem(e) {
    var index = e.data.index;
    $options.eq( index ).prop('selected', false);
    _updateTags();
  }
}

//-----------------------------------------------------
// fontSizeChange
//-----------------------------------------------------
function fontSizeChange() {

  var $txtBox = $('.tabArrival_inItemLinkDef');

  $txtBox.each(function(){

    var $txt1 = $('.tabArrival_inItemLinkDefTtl');
    var $txt2 = $('.tabArrival_inItemLinkDefDesc');
    var txtLen1 = $('.tabArrival_inItemLinkDefTtl', this).text().length;
    var txtLen2 = $('.tabArrival_inItemLinkDefDesc', this).text().length;
    var txtLenTotal = txtLen1 + txtLen2;

    if(txtLenTotal > 30) {
      $(this).addClass('is-long');
    }

  });

}

//-----------------------------------------------------
// searchLinkHover
//-----------------------------------------------------
function searchLinkHover() {

  var $linkWrite = $('.boxRecTeamList_writeLink');
  var $linkImg = $('.boxRecTeamList_inInIcoImg');
  var $linkTxt = $('.boxRecTeamList_inInIcoTxtIn');
  var className01 = '.boxRecTeamList_inInIcoTxtIn';
  var className02 = '.imgCover';
  var className03 = '.boxRecTeamList_inInIcoImg';
  var className04 = '.boxRecTeamList_inInIcoTxt';
  var className05 = '.boxRecTeamList_writeLink';

  $linkWrite.hover(

    function() {
      var $this = $(this);
      $this.parent().parent().find(className01).addClass('is-change')
      $this.parent().parent().find(className02).addClass('is-change')
    },

    function() {
      var $this = $(this);
      $this.parent().parent().find(className01).removeClass('is-change')
      $this.parent().parent().find(className02).removeClass('is-change')
    }

  );

  $linkImg.hover(

    function() {
      var $this = $(this);
      $this.parent().parent().parent().parent().find(className05).addClass('is-change')
    },

    function() {
      var $this = $(this);
      $this.parent().parent().parent().parent().find(className05).removeClass('is-change')
    }

  );

  $linkTxt.hover(

    function() {
      var $this = $(this);
      $this.parent().parent().parent().find(className02).addClass('is-change')
      $this.parent().parent().parent().parent().parent().find(className05).addClass('is-change')
    },

    function() {
      var $this = $(this);
      $this.parent().parent().parent().find(className02).removeClass('is-change')
      $this.parent().parent().parent().parent().parent().find(className05).removeClass('is-change')
    }

  );

}

//-----------------------------------------------------
// infoLoading
//-----------------------------------------------------

function moreBtn() {

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  function _onPageshow() {
    // 開始処理
    // 表示させる要素の総数をlengthメソッドで取得
    var $numberListLen = $(".listPickup li").length;
    // デフォルトの表示数
    var defaultNumPC = 6;
    var defaultNumSP = 5;
    // ボタンクリックで追加表示させる数
    var addNum = 4;
    // 現在の表示数
    var currentNum = 0;

    var breakpoint = 767;
    var lastWW = $(window).width();

    $(".listPickup").each(function() {
      // 初期表示のボタンの表示非表示
      if($numberListLen <= 4) {
        $(".listPickup_btn-more").hide();
        $(".listPickup_btn-close").hide();
      } else {
        $(".listPickup_btn-more").show();
        $(".listPickup_btn-close").hide();
      }
      // moreボタンを表示し、closeボタンを隠す
      // $(this).find(".listParty_btn").show();
      // $(this).find(".listParty_btn-close").hide();

      // defaultNumの数だけ要素を表示
      // defaultNumよりインデックスが大きい要素は隠す

      if (lastWW <= breakpoint) {
        $(this).find("li:not(:lt("+defaultNumSP+"))").hide();
      } else {
        $(this).find("li:not(:lt("+defaultNumPC+"))").hide();
      }
      // $(this).find("li:not(:lt("+defaultNum+"))").hide();

      // 初期表示ではデフォルト値が現在の表示数となる
      if (lastWW <= breakpoint) {
        currentNum = defaultNumSP;
      } else {
        currentNum = defaultNumPC;
      }
      // currentNum = defaultNum;

      // moreボタンがクリックされた時の処理
      $(".listPickup_btn-more").click(function() {
        // 現在の表示数に追加表示数を加えていく
        currentNum += addNum;

        // 現在の表示数に追加表示数を加えた数の要素を表示する
        $(this).parent().find("li:lt("+currentNum+")").slideDown();

        // 表示数の総数よりcurrentNumが多い=全て表示された時の処理
        if($numberListLen <= currentNum) {
          // 現在の表示数をデフォルト表示数へ戻す
          if (lastWW <= breakpoint) {
            currentNum = defaultNumSP;
          } else {
            currentNum = defaultNumPC;
          }
          // currentNum = defaultNum;
          // インデックス用の値をセット
          indexNum = currentNum - 1;

          // moreボタンを隠し、closeボタンを表示する
          $(".listPickup_btn-more").hide();
          $(".listPickup_btn-close").show();

          // closeボタンがクリックされた時の処理
          $(".listPickup_btn-close").click(function() {
            // デフォルト数以降=インデックスがindexNumより多い要素は非表示にする
            $(this).parent().find("li:gt("+indexNum+")").slideUp();

            // closeボタンを隠し、moreボタンを表示する
            $(this).hide();
            $(".listPickup_btn-more").show();
          });
        }
      });
    });

  }

}

//-----------------------------------------------------
// modalEnrty
//-----------------------------------------------------

function modalEnrty() {

  var $remodalSlide = $('.remodalSlide');

  if($remodalSlide.length){

    var $closeBtn = $('.remodalClose');

    $closeBtn.on('click', function(){

      var $list = $('.remodalSlide_list');

      $list.scrollLeft(0);

    });

    var $win = $(window);

    if ( window.pageshown ) {
      _onPageshow();
    }
    else {
      $win.on('pageshow', _onPageshow);
    }
    $win.on('resize', _onResize);

    function _onPageshow() {
      _resize();
    }

    function _onResize() {
      _resize();
    }

    function _resize() {

      var $nextBtn01 = $('.remodalSlide_next01');
      var $nextBtn02 = $('.remodalSlide_next02');
      var $prevBtn01 = $('.remodalSlide_prev01');
      var $prevBtn02 = $('.remodalSlide_prev02');
      var $list = $('.remodalSlide_list');
      var widthSP = $('.remodal-slide').width();

      if ($win.width() < 768) {
        $nextBtn01.on('click', function(){
          $list.animate({scrollLeft:widthSP},600,'swing');
        });
        $nextBtn02.on('click', function(){
          $list.animate({scrollLeft:(widthSP * 2)},600,'swing');
        });
        $prevBtn01.on('click', function(){
          $list.animate({scrollLeft:0},600,'swing');
        });
        $prevBtn02.on('click', function(){
          $list.animate({scrollLeft:widthSP},600,'swing');
        });
      }
      else {
        $nextBtn01.on('click', function(){
          $list.animate({scrollLeft:600},400,'swing');
        });
        $nextBtn02.on('click', function(){
          $list.animate({scrollLeft:1200},400,'swing');
        });
        $prevBtn01.on('click', function(){
          $list.animate({scrollLeft:0},400,'swing');
        });
        $prevBtn02.on('click', function(){
          $list.animate({scrollLeft:600},400,'swing');
        });
      }
    }

  }

}

//-----------------------------------------------------
// checkEntry
//-----------------------------------------------------
function checkEntry() {

  var len1 = $('.remodalSlide .remodalMember_item').length;
  var len2 = $('.inputTeam[disabled="disabled"]').length;
  if(len1 == len2) {
    $(".remodalSlide_next").prop('disabled', true);;
  } else {
    return false;
  }

}

//-----------------------------------------------------
// recommendSlide
//-----------------------------------------------------
function recommendSlide(){
  window.onpageshow = function(event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $(window).on('pageshow', _onPageshow);
  }
  function _onPageshow() {
    if (_onPageshow.persisted) {
      window.location.reload();
    }
    // $("#js-recommendSlide > div").css({"position":"relative","overflow":"hidden","width":$width,"height":$height});
    // $("#js-recommendSlide > div").hide();
    $(function(){
      // 設定
      var $width =460; // 横幅
      var $height =500; // 高さ
      var $interval = 8000; // 切り替わりの間隔（ミリ秒）
      var $fade_speed = 1000; // フェード処理の早さ（ミリ秒）

      if($('.topNew').length){
        $("#js-recommendSlide > div:first").addClass("active").show();
        $('#js-progressbar').LineProgressbar({
          duration: $interval,
          ShowProgressCount: false
        });

        setInterval(function(){
          var $active = $("#js-recommendSlide > div.active");
          var $next = $active.next("div").length?$active.next("div"):$("#js-recommendSlide > div:first");
          $active.fadeOut($fade_speed).removeClass("active");
          $next.fadeIn($fade_speed).addClass("active");
          $('#js-progressbar').LineProgressbar({
            duration: $interval,
            ShowProgressCount: false
          });
        },$interval);
      }
    });
  }
}


//-----------------------------------------------------
// sliderTopNew
//-----------------------------------------------------
function sliderTopNew() {

  var $win = $(window);
  var $sliderTeammember = $('.boxTopMain_recommendTeammemberSlideList');

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }

  function _onPageshow() {
    // 開始処理
    $sliderTeammember.slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    });
  }

}

//-----------------------------------------------------
// fixedScrollTop
//-----------------------------------------------------
function fixedScrollTop() {
  var slide = $('.boxTopMain_recommendTeammemberSlideList');

  if ( slide.length ) {
    var target = $('.top .asideTop, .topNew .asideTop');
    var footer = $('.top .boxCompanyTop, .topNew .boxCompanyTop');
    slide.on('init', function(){
      var targetHeight = target.outerHeight(true);
      var targetTop = target.offset().top - 30;
      // var targetTop = target.offset().top;

      $(window).scroll(function(){

        var scrollTop = $(this).scrollTop();

        if(scrollTop > targetTop){

          var footerTop = footer.offset().top -45;

          if(scrollTop + targetHeight > footerTop){

            var customTopPosition = footerTop - (scrollTop + targetHeight)
            target.addClass('is-fixed').css({top: customTopPosition + 'px'});

          } else {

            target.addClass('is-fixed').css({top: 0});

          }

        } else {

          target.removeClass('is-fixed').css({top: 0});

        }

      });

    });
  } else if( $('.topNew .asideTop').length ){
    var target = $('.asideTop');
    var footer = $('.boxCompanyTop');
    var targetHeight = target.outerHeight(true);
    var targetTop = target.offset().top - 250;
    // var targetTop = target.offset().top;

    $(window).scroll(function(){

      var scrollTop = $(this).scrollTop();

      if(scrollTop > targetTop){

        var footerTop = footer.offset().top -45;

        if(scrollTop + targetHeight > footerTop){

          var customTopPosition = footerTop - (scrollTop + targetHeight)
          target.addClass('is-fixed').css({top: customTopPosition + 'px'});

        } else {

          target.addClass('is-fixed').css({top: 0});

        }

      } else {

        target.removeClass('is-fixed').css({top: 0});

      }

    });

  }

}

$(function(){
  $('.js-radio-other').change(function(){
    $('#js-search-terms-01').addClass('is-visible')
    $('#js-search-terms-01').removeClass('is-hide')
    $('#js-search-terms-02').addClass('is-hide')
  });
  $('#js-radio-business').change(function(){
    $('#js-search-terms-01').removeClass('is-visible')
    $('#js-search-terms-01').addClass('is-hide')
    $('#js-search-terms-02').removeClass('is-hide')
  });
});

//-----------------------------------------------------
// scrollContact()
//-----------------------------------------------------
function scrollContact() {

  var $nextBtn = $('.boxService_formWrap');
  var $footer = $('.footer');

  if($nextBtn.length){
    // $("#topBtn").hide();
    $(window).on("scroll", function() {
      if ($(this).scrollTop() > 500) {
        // $("#topBtn").fadeIn("fast");
        $nextBtn.addClass('is-visible')
      } else {
        // $("#topBtn").fadeOut("fast");
        $nextBtn.removeClass('is-visible')
      }
      scrollHeight = $(document).height();
      scrollPosition = $(window).height() + $(window).scrollTop();
      footHeight = $footer.innerHeight(); //footerの高さ（＝止めたい位置）
      // if ( scrollHeight - scrollPosition  <= footHeight - 90 + 240 ) {
      if ( scrollHeight - scrollPosition  <= footHeight + 1 ) {
        $nextBtn.css({
          "position":"absolute",
          // "bottom": footHeight - 90 + 265
          // "bottom": footHeight + 1
        });
      } else {
        $nextBtn.css({
          "position":"fixed",
          // "bottom": "20px"
          "bottom": "0"
        });
      }
    });
  }
}

//-----------------------------------------------------
// teamSelect
//-----------------------------------------------------
function teamSelect() {

  var $win = $(window);

  if ( window.pageshown ) {
    _onPageshow();
  }
  else {
    $win.on('pageshow', _onPageshow);
  }

  function _onPageshow() {

    if($('.boxProf_editDefDesc-create').length) {
      var $memberFirst = $('.remodalMember_item:nth-of-type(1)');
      var $teamName = $('.boxProf_editDefDesc-create > p > span');
      var $icoOrigin = $('.remodalMember_item:nth-of-type(1) .remodalMember_itemLabelInImg')
      var $ico = $('.boxProf_editDefDesc-create > p > img')
      var memberFirstTxt = $memberFirst.text();
      var icoSrc = $icoOrigin.attr('src');

      $teamName.text(memberFirstTxt);
      $ico.attr('src', icoSrc)

      var $confirmBtn = $('.remodalConfirm-create');

      $confirmBtn.on('click', function(){

        var $input = $('.remodalMember_item input');

        $input.each(function(){
          var $this = $(this);

          if($this.prop('checked')) {

            var icoSrc02 = $(this).next().find('.remodalMember_itemLabelInImg').attr('src');
            var memberTxt = $(this).next().find('span').text();

            $ico.attr('src', icoSrc02);
            $teamName.text(memberTxt);

          }

        });

      });

    }

  }

}
