// 募集検索
$(document).on('click','#js-deleteBtn',function(){
  $('form.employ_search').children().remove('.search-item')
  $('input.asideTop_itemInput.areaRadio_itemInput.typeAll').prop('checked', true);
});

// プロジェクト検索
$(document).on('click','#js-project-search-deleteBtn',function(){
  var $deleteTxt = $('.asideTop_defDesc');
  var $remodalInput = $('.remodal input');
  var $remodalListChild = $('.remodal .selectList_child');
  var $remodalLabel = $('.remodal label');

  $deleteTxt.text('');
  $remodalInput.prop('checked', false);
  $remodalListChild.css('display','none');
  $remodalListChild.addClass('is-close');
  $remodalLabel.removeClass('expanded');
});

$(document).on('click','#employ-btn-search',function(){
  $('[data-remodal-id="job"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `job_type_sub_categories_id_in_${i} search-item`,
      name: 'q[job_type_sub_categories_id_in][]',
      value: dom.value
    }).appendTo('form');
  });

  $('[data-remodal-id="category"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `work_category_items_id_in_${i} search-item`,
      name: 'q[work_category_items_id_in][]',
      value: dom.value
    }).appendTo('form');
  });

  $('[data-remodal-id="tag"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `tags_id_in_${i} search-item`,
      name: 'q[tags_id_in][]',
      value: dom.value
    }).appendTo('form');
  });

  $('[data-remodal-id="area"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `region_items_id_in_${i} search-item`,
      name: 'q[region_items_id_in][]',
      value: dom.value
    }).appendTo('form');
  });
});

// TOPページのチーム招待
$(document).on('click','.top-team-invite-submit',function(){
  let form = $(this).parents('form');
  let teamId = $(this).data('team-id');

  $(this).parents('form').find('#team_id').remove()
  $(this).parents('form').find('#email').remove()

  $('<input>').attr({
    'type': 'hidden',
    'name': 'id',
    'id': 'team_id',
    'value': teamId
  }).appendTo(form);

  let email = $('.boxMember').find(`#team_invite_mail_${teamId}`).val()
  $('<input>').attr({
    'type': 'hidden',
    'name': 'email',
    'id': 'email',
    'value': email
  }).appendTo(form);
})

$(document).on('click','.inputTeam',function(){
  $('.remodalChange').on('click', function() {
    let error = $('.boxMember').find('.top_team_invitations')
    error.text('')
  });
})

// コンタクトの選択制御
$(document).ready(function() {
  $('input.inputTeam').change(function() {
    $('.contact-message-btn').prop("disabled", false)
  });

  $('textarea.contact-multi-textarea-original').keyup(function() {
    const text = $.trim($('.contact-multi-textarea-original').val())
    if (text.length !== 0) {
      $('.contact-next-textarea').prop("disabled", false)
    } else {
      $('.contact-next-textarea').prop("disabled", true)
    }
  });
})

// TODO: フロントで共通化
// チーム検索時のスキル選択
$(document).on('confirmation', '[data-remodal-id="skill"] ', function () {

  var checked = [];

  $('[data-remodal-id="skill"] input:checked').each(function(i,dom){

    checked.push( $(dom).parent().text().replace(/\s+/g,'') );

  });

  var $tag = $('#js-skill');

  $tag.html( checked.join('&nbsp;/&nbsp;') );


  var $win = $(window);
  var cutFigurePC = '25';
  var cutFigureSP = '35';
  var afterTxt = ' ...';
  var textLength = $tag.text().length;
  var textTrimPC = $tag.text().substr(0,(cutFigurePC));
  var textTrimSP = $tag.text().substr(0,(cutFigureSP));

  if ($win.width() > 768) {
    if(cutFigurePC < textLength) {
      $tag.html(textTrimPC + afterTxt).addClass('is-visible');
    } else if(cutFigurePC >= textLength) {
      $tag.addClass('is-visible');
    }
  } else {
    if(cutFigureSP < textLength) {
      $tag.html(textTrimSP + afterTxt).addClass('is-visible');
    } else if(cutFigureSP >= textLength) {
      $tag.addClass('is-visible');
    }
  }

});

// プロジェクトタグの選択
$(document).on('confirmation', '[data-remodal-id="project_tag"] ', function () {

  var checked = [];

  $('[data-remodal-id="project_tag"] input:checked').each(function(i,dom){

    checked.push( $(dom).parent().text().replace(/\s+/g,'') );

  });

  var $category = $('#js-project_tag');

  $category.html( checked.join('&nbsp;/&nbsp;') );

  var $win = $(window);
  var cutFigurePC = '25';
  var cutFigureSP = '35';
  var afterTxt = ' ...';
  var textLength = $category.text().length;
  var textTrimPC = $category.text().substr(0,(cutFigurePC));
  var textTrimSP = $category.text().substr(0,(cutFigureSP));

  if ($win.width() > 768) {
    if(cutFigurePC < textLength) {
      $category.html(textTrimPC + afterTxt).addClass('is-visible');
    } else if(cutFigurePC >= textLength) {
      $category.addClass('is-visible');
    }
  } else {
    if(cutFigureSP < textLength) {
      $category.html(textTrimSP + afterTxt).addClass('is-visible');
    } else if(cutFigureSP >= textLength) {
      $category.addClass('is-visible');
    }
  }

});

$(document).on('click','#project-btn-search',function(){
  $('[data-remodal-id="project_tag"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `project_tags_id_in_${i} search-item`,
      name: 'q[project_tags_id_in][]',
      value: dom.value
    }).appendTo('form');
  });
});


// 職種選択
$(document).on('click','#add-sign-up-job-type-btn',function(){
  $('form input[type=hidden].member-wanted-job-select-item').remove()
  $('.boxRecInfo_selOcc.member-wanted-job').empty()

  $('[data-remodal-id="job"] input:checked').each(function(i, dom){
    $('<input>').attr({
      type: 'hidden',
      class: `member_wanted_jobs_id_${dom.value} member-wanted-job-select-item`,
      name: 'member_wanted[job_type_sub_category_ids][]',
      value: dom.value
    }).appendTo('form');

    let text = $('span[for=job_' + dom.value + ']').text();
    $(`<p class="boxRecInfo_editDefDescWord">${text}<button type="button" class="boxRecInfo_editDefDescWordBtn btn-member-wanted-job" id="job_type_sub_category_id_${dom.value}"></button></p>`)
      .appendTo('.boxRecInfo_selOcc.member-wanted-job')
  });
});

$(document).on('click','.boxRecInfo_editDefDescWordBtn.btn-member-wanted-job',function(){
  const id = $(this).attr('id')
  const number = id.match(/[0-9]*$/)[0]|0;

  $(`input[type=hidden]#${id}`).remove();
  $(this).parent().remove()
  $(`#job_${number}`).prop('checked', false)
});

// メンバー募集のチームセレクトのID指定
$(document).on('click', '.remodalConfirm-create', function(){
  var $input = $('.remodalMember_item input');
  $input.each(function(){
    var $this = $(this);
    if($this.prop('checked')) {
      $(".member_wanted_hidden_input").remove();

      let team_id = $this.attr('value').replace(/[^0-9]/g, '')
      $('<input>').attr({
        type: 'hidden',
        name: 'member_wanted[team_id]',
        class: 'member_wanted_hidden_input',
        value: team_id
      }).appendTo('form');
    }
  });
});

// メンバー募集のチームセレクトで各メンバー募集のeditに遷移
$(document).on('click', '.remodalConfirm-team-wanted-edit', function(){
  var $input = $('.remodalMember_item input');
  $input.each(function(){
    var $this = $(this);
    if($this.prop('checked')) {
      $(".member_wanted_hidden_input").remove();

      let path = $this.attr('path')
      location.href = path
    }
  });
});
