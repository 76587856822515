/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';
global.$ = jQuery;
import Rails from 'rails-ujs';
Rails.start();
import './js/common/script.js'
import './js/common/jquery-1.12.4.min.js'
import './js/common/remodal.min.js'
import './js/common/slick/slick.js'
import './js/common/lineProgressbar/jquery.lineProgressbar.js'
import './js/common/lineProgressbar/jquery.lineProgressbar.css'
import './custom/front.js'
import './custom/scout_search.js'
import './custom/member_search.js'
import './js/common/slick/slick.scss'
import './js/common/slick/slick-theme.scss'
import '../src/application.scss'
import './user_common.js'
import './user_sign_up.js'
import './message.js'
require.context('../assets/images/', true)
require.context('../business/assets/images/', true)
require.context('../recruiterlp/assets/images/', true)
